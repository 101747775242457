<template>
  <a
    href="#Z"
    title="頁尾網站資訊，此區有個資政策、快速連結等資訊"
    id="Z"
    accesskey="Z"
    name="Z"
    class="visually-hidden-focusable"
    >:::</a
  >
  <!-- 頁尾 開始-->
  <footer class="foot-bg-color text-white m-0 pt-5 pb-5 position-relative" style="z-index:10">
    <div
      class="container overflow-hidden"
      ref="target1"
    >
      <div class="row g-3">
        <div class="col-6 col-lg-3 p-3 p-lg-5">
          <img class="img-fluid" src="/images/home/foot/foot-1.png" alt="" />
        </div>
        <div class="col-6 col-lg-3 p-3 p-lg-5">
          <img class="img-fluid" src="/images/home/foot/foot-2.png" alt="" />
        </div>
        <div class="col-6 col-lg-3 p-3 p-lg-5">
          <img class="img-fluid" src="/images/home/foot/foot-3.png" alt="" />
        </div>
        <div class="col-6 col-lg-3 p-3 p-lg-5">
          <img class="img-fluid" src="/images/home/foot/foot-4.png" alt="" />
        </div>
        <div class="col-12 text-center pt-4 mt-3 font-1-2em">
          <p class="m-0 mb-1">世紀末的對話-數位教材製作與應用計畫</p>
          <p class="m-0 mb-1">Copyright © 2023 All rights reserved</p>
          <!-- <p class="m-0 mb-1">本站建議使用瀏覽器：Google Chrome 或 Mozilla Firefox，並將螢幕解析度設定為1360*768以上，瀏覽器畫面縮放維持100%，以獲得最佳瀏覽效果</p> -->
          <!-- <p class="m-0">造訪人次 : 10243195</p> -->
        </div>
      </div>
    </div>
  </footer>
  <!-- 頁尾 結束-->
</template>

<script>
import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

export default {
  name: "Header",
  props: {},
  setup() {
    const target1 = ref(null);
    const targetIsVisible1 = ref(false);
    // eslint-disable-next-line
    const { stop1 } = useIntersectionObserver(
      target1,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        targetIsVisible1.value = isIntersecting;
      }
    );
    return {
      target1,
      targetIsVisible1,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
