<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="mobile-menu"
    tabindex="-1"
    aria-labelledby="mobile-menu"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">
            <img
              style="width: 50%"
              class="d-block"
              alt="回首頁"
              src="/images/home/head/logo-blue.svg"
            />
          </h1>
          <button
            type="button"
            style="width: 20%"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-row justify-content-between pb-2 section-title mb-1">
            <h2 class="fw-bold fs-6">
              <i aria-hidden="true" class="fa-solid fa-fw fa-bars text-primary me-2"></i
              >主選單
            </h2>
          </div>
          <div class="accordion border accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <router-link
                  class="accordion-button-link collapsed text-decoration-none"
                  to="/"
                >
                  首頁
                </router-link>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse1"
                  aria-expanded="false"
                  aria-controls="flush-collapse1"
                >
                  關於計畫
                </button>
              </h2>
              <div
                id="flush-collapse1"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body p-0">
                  <div class="list-group rounded-0">
                    <router-link
                      to="/about_project/chinese"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >計畫簡介(中文)</router-link
                    >
                    <router-link
                      to="/about_project/english"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >計畫簡介(英語)</router-link
                    >
                    <router-link
                      to="/about_project/aboriginal"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >計畫簡介(原住民語)</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <router-link
                  class="accordion-button-link collapsed text-decoration-none"
                  to="/team"
                >
                  製作團隊
                </router-link>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse2"
                  aria-expanded="false"
                  aria-controls="flush-collapse2"
                >
                  數位教材
                </button>
              </h2>
              <div
                id="flush-collapse2"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body p-0">
                  <div class="list-group rounded-0">
                    <router-link
                      to="/textbook/chapter1"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第一集：各懷心思的啟航</router-link
                    >
                    <router-link
                      to="/textbook/chapter2"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第二集：驚異衝擊的旅程</router-link
                    >
                    <router-link
                      to="/textbook/chapter3"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第三集：鏡裡鏡外的東京</router-link
                    >
                    <router-link
                      to="/textbook/chapter4"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第四集：湧動矛盾的參訪</router-link
                    >
                    <router-link
                      to="/textbook/chapter5"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第五集：背道而馳的歸途</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse3"
                  aria-expanded="false"
                  aria-controls="flush-collapse3"
                >
                  數位教具
                </button>
              </h2>
              <div
                id="flush-collapse3"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body p-0">
                  <div class="list-group rounded-0">
                    <router-link
                      to="/textbook/ar"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >AR虛擬角色體驗</router-link
                    >
                    <router-link
                      to="/textbook/vr"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >VR360互動教具</router-link
                    >
                    <router-link
                      to="/instructional_materials/face_tattoo"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >臉部刺青合成</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <router-link
                  class="accordion-button-link collapsed text-decoration-none"
                  to="/poker"
                >
                  衍生設計
                </router-link>
              </h2>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse4"
                  aria-expanded="false"
                  aria-controls="flush-collapse4"
                >
                  活動花絮
                </button>
              </h2>
              <div
                id="flush-collapse4"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body p-0">
                  <div class="list-group rounded-0">
                    <router-link
                      to="/workshop"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >教學工作坊</router-link
                    >
                    <router-link
                      to="/teaching_observation"
                      class="list-group-item list-group-item-action"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >教學觀摩</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 電腦版-頁首 開始-->
  <header class="head-bg-color w-100 d-none d-xl-block" :class="{ scrolled: isScrolled }">
    <div class="container-fluid">
      <nav class="pt-2 pb-2">
        <div class="d-flex p-0">
          <div class="logo-div d-flex align-items-center justify-content-start">
            <h1 class="logo p-0 m-0">
              <router-link class="navbar-brand d-flex p-0 m-0" to="/">
                <img
                  class="d-block"
                  alt="回首頁"
                  src="/images/home/head/logo-white.svg"
                />
              </router-link>
            </h1>
          </div>
          <div class="d-flex flex-grow-1 flex-column justify-content-center">
            <div class="d-flex justify-content-end h-100">
              <router-link
                class="button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                to="/"
              >
                <span class="d-block fw-bold" style="font-size: 1.2em">首頁</span>
              </router-link>
              <div class="dropdown">
                <a
                  class="dropdown-toggle button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <span class="d-block fw-bold" style="font-size: 1.2em">關於計畫</span>
                </a>
                <ul class="dropdown-menu border-0 rounded-0 p-0">
                  <li>
                    <router-link
                      to="/about_project/chinese"
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >計畫簡介(中文)</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/about_project/english"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >計畫簡介(英語)</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/about_project/aboriginal"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >計畫簡介(原住民語)</router-link
                    >
                  </li>
                </ul>
              </div>
              <router-link
                class="button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                to="/team"
              >
                <span class="d-block fw-bold" style="font-size: 1.2em">製作團隊</span>
              </router-link>
              <div class="dropdown">
                <a
                  class="dropdown-toggle button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <span class="d-block fw-bold" style="font-size: 1.2em">數位教材</span>
                </a>
                <ul class="dropdown-menu border-0 rounded-0 p-0">
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/chapter1"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第一集：各懷心思的啟航</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/chapter2"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第二集：驚異衝擊的旅程</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/chapter3"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第三集：鏡裡鏡外的東京</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/chapter4"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第四集：湧動矛盾的參訪</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/chapter5"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >第五集：背道而馳的歸途</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <span class="d-block fw-bold" style="font-size: 1.2em">數位教具</span>
                </a>
                <ul class="dropdown-menu border-0 rounded-0 p-0">
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/ar"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >AR虛擬角色體驗</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/textbook/vr"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >VR360互動教具</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/instructional_materials/face_tattoo"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >臉部刺青合成</router-link
                    >
                  </li>
                </ul>
              </div>
              <router-link
                class="button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                to="/poker"
              >
                <span class="d-block fw-bold" style="font-size: 1.2em">衍生設計</span>
              </router-link>
              <div class="dropdown">
                <a
                  class="dropdown-toggle button-4 ms-2 pt-2 pb-2 ps-3 pe-3 d-flex flex-row text-decoration-none align-items-center align-self-center"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <span class="d-block fw-bold" style="font-size: 1.2em">活動花絮</span>
                </a>
                <ul class="dropdown-menu border-0 rounded-0 p-0">
                  <li>
                    <router-link class="dropdown-item pt-3 pb-3 ps-4 pe-4" to="/workshop"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >教學工作坊</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item pt-3 pb-3 ps-4 pe-4"
                      to="/teaching_observation"
                      ><i
                        class="fa-solid fa-angles-right me-2 small-icon text-secondary"
                      ></i
                      >教學觀摩</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <!-- 電腦版-頁首 結束-->
  <!-- 行動版-頁首 開始-->
  <header
    class="head-bg-color w-100 main-bg-color d-xl-none"
    :class="{ scrolled: isScrolled }"
  >
    <div class="container">
      <div class="pt-2 pb-2 d-flex flex-row justify-content-between align-content-center">
        <h1 class="logo p-0 m-0">
          <router-link class="navbar-brand d-flex p-0 m-0" to="/">
            <img class="d-block" alt="回首頁" src="/images/home/head/logo-white.svg" />
          </router-link>
        </h1>
        <button
          class="btn"
          data-bs-toggle="modal"
          data-bs-target="#mobile-menu"
          type="button"
          value="開啟主選單按鈕"
          aria-label="開啟主選單按鈕"
        >
          <i class="fa-solid fa-fw fa-bars text-white" style="font-size: 1.5em"></i>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Head",
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    init() {
      window.addEventListener("scroll", this.handleScroll);
    },
    getMenuId(id, hash) {
      if (hash) {
        return "#menu" + id;
      } else {
        return "menu" + id;
      }
    },
    getInternalto(path) {
      return "/" + path;
    },
    getExternalLinkTitle(title) {
      return "移至" + title + "頁面(另開視窗)";
    },
    getMenuTitle(title, type) {
      if (type === "dropdown") {
        return "開啟" + title + "選單";
      } else {
        return "移至" + title + "頁面";
      }
    },
    toDirect(path) {
      this.$router.push({
        name: path,
        // params: {
        //   id: this.$route.params.id
        // }
      });
    },
    handleScroll() {
      if (window.scrollY > 100) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header {
  position: fixed;
  z-index: 99;
}
.head-bg-color {
  background-color: transparent; /* 初始背景颜色 */
  transition: background-color 0.3s; /* 添加过渡效果 */
}
.head-bg-color.scrolled {
  background-color: rgba(0, 62, 87, 0.9); /* 滚动时的背景颜色 */
  -webkit-filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0px 5px 7px rgba(0, 0, 0, 0.7));
}
.accordion-button-link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
</style>
